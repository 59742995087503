import React from "react";

const Header = () => {
  return (
    <header className="App-header">
      <div className="App-logo">
        <img src="logo.png" alt="Perro's Bar Logo" />
      </div>
    </header>
  );
};
export default Header;
