import axios from "axios";
import { useEffect, useState } from "react";

export default function useStatus() {
  const [status, setStatus] = useState(null);
  const endpoint = process.env.REACT_APP_STATUS_API_URL || "";

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const result = await axios(endpoint);
        setStatus(result.data.status);
      } catch (err) {
        setStatus("error");
      }
    };
    fetchStatus();
  }, [endpoint]);

  return [status];
}
