import React from "react";
import useStatus from "../useStatus";
import statusContent from "../content/status";

const Home = () => {
  const [status] = useStatus();

  return (
    <main>
      <section className="App-status-heading">Perro's is:</section>
      <section className={`App-status App-status--${status}`}>
        {statusContent[status]}
      </section>
    </main>
  );
};
export default Home;
