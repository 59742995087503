import React from "react";
import "./App.css";

import { Router } from "@reach/router";

import Home from "./components/Home";
import Header from "./components/Header";

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Home path="/" />
      </Router>
    </div>
  );
}

export default App;
